<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel :header="$t('menu.devices')">
                        <DataTable
                            :lazy="true"
                            :value="items"
                            :paginator="true"
                            :rows="perPage"
                            :loading="loading"
                            class="p-mt-3"
                            :totalRecords="totalPages"
                            @page="onPage($event)"
                            @sort="onSort($event)"
                            @filter="onFilter($event)"
                            ref="datatable"
                            responsiveLayout="scroll"
                            :paginatorBottom="true"
                            stateStorage="session"
                            stateKey="dt-state-devices"
                            v-model:expandedRows="expandedRows"
                        >
                            <template #header>
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-2">
                                        <Dropdown style="width: 100%" :showClear="true" v-model="shopsModel" :options="shops" optionLabel="name" :placeholder="$t('search.byShop')" :filter="!this.isMobile()" />
                                    </div>
                                    <div class="p-col-12 p-md-2">
                                        <Dropdown style="width: 100%" v-model="activeFilter" :options="activeOptions" :placeholder="$t('search.byStatus')" optionLabel="option" :filter="!this.isMobile()" />
                                    </div>
                                </div>
                            </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column :expander="true" headerStyle="width: 3rem"> </Column>
                            <Column field="internalId" :header="$t('system.id')"></Column>
                            <Column field="friendlyName" :header="$t('sportsSettings.name')"></Column>
                            <Column field="shopId" :header="$t('system.shop')" :sortable="true">
                                <template #body="slotProps"> {{ shopsDictionary[slotProps.data.shopId] }}</template></Column
                            >
                            <Column field="displayedGame" :header="$t('system.displayedGame')" :sortable="true">
                                <template #body="slotProps"> {{ getGameName(slotProps.data.productUrl) }}</template>
                            </Column>
                            <Column>
                                <template #body="{ data }">
                                    <Button v-show="data.isChanged" :label="$t('buttons.reset')" @click="resetDevice(data.internalId)" class="p-button-info p-button-text p-mr-2 p-mb-2" />
                                </template>
                            </Column>
                            <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, editPage)">
                                <template #body="{ data }">
                                    <Button v-if="!data.isActive" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="deleteDevice(data.internalId)" />
                                </template>
                            </Column>
                            <template #expansion="{ data }">
                                <EditDevice
                                    @refresh="refresh"
                                    :isEditable="!data.isActive"
                                    :deviceId="data.id"
                                    :deviceName="data.friendlyName"
                                    :deviceInternalId="data.internalId"
                                    :deviceProductUrl="data.productUrl"
                                    :deviceShopId="data.shopId"
                                    :deviceVersion="data.version"
                                ></EditDevice>
                            </template>
                        </DataTable>
                    </TabPanel>
                    <TabPanel :header="$t('menu.pendingDevices')">
                        <DataTable :value="pendingItems" v-model:expandedRows="expandedRows" :paginator="true" :rows="perPage" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column :expander="true" headerStyle="width: 3rem"> </Column>
                            <Column field="internalId" :header="$t('system.id')"></Column>
                            <Column field="friendlyName" :header="$t('sportsSettings.name')"></Column>
                            <Column field="shopId" :header="$t('system.shop')" :sortable="true">
                                <template #body="{ data }"> {{ shopsDictionary[data.shopId] }}</template></Column
                            >
                            <Column field="displayedGame" :header="$t('system.displayedGame')" :sortable="true">
                                <template #body="{ data }"> {{ getGameName(data.productUrl) }}</template>
                            </Column>
                            <Column field="version" :header="$t('system.version')"></Column>
                            <template #expansion="{ data }">
                                <EditDevice
                                    :isEditable="false"
                                    :deviceId="data.id"
                                    :deviceName="data.friendlyName"
                                    :deviceInternalId="data.internalId"
                                    :deviceProductUrl="data.productUrl"
                                    :deviceShopId="data.shopId"
                                    :deviceVersion="data.version"
                                    @refresh="refresh"
                                ></EditDevice>
                            </template>
                        </DataTable>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import settings from '../../settings/generalSettings';
import EditDevice from './EditDevice.vue';
export default {
    components: {
        EditDevice,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            pendingItems: [],
            BoApi: new BoApi(),
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'devices',
            activeFilter: { id: '100', option: 'All' },
            filtersInitializationDone: false,
            shops: [],
            shopsModel: { _id: 'all', name: 'All' },
            defaultShopId: 'all',
            shopsDictionary: [],
            expandedRows: [],
            activeTab: 0,
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
        activeOptions() {
            return [
                {
                    id: '100',
                    option: this.$t('general.all'),
                },
                {
                    id: '1',
                    option: this.$t('general.active'),
                },
                {
                    id: '0',
                    option: this.$t('general.inactive'),
                },
            ];
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    watch: {
        activeFilter() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        shopsModel() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        activeTab() {
            this.refresh();
        },
    },
    mounted() {
        this.BoApi.getShops('')
            .then((response) => {
                this.shops = response.data;
                this.createShopsDictionary();
                this.shops.unshift(this.shopsModel);
                var unassignedShop = { id: 'UNASSIGNED', option: 'UNASSIGNED' };
                this.shops.push(unassignedShop);
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
        if (this.storeFilterData != undefined) {
            this.sort = this.storeFilterData.data.sort;
            this.activeFilter = this.activeOptions.find((s) => s.id == this.storeFilterData.data.filter);
            this.shopsModel = this.shops.find((s) => s.id == this.storeFilterData.data.shopId);
            this.readItems(this.storeFilterData.data.page);
        } else {
            this.readItems();
        }
        this.getPendingDevices();
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            const params = {
                per_page: this.perPage,
                page: page,
                sort: this.sort,
                active: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                shopid: this.notNullOrEmptyObject(this.shopsModel) ? this.shopsModel._id : this.defaultShopId,
            };
            this.BoApi.getDevices(params)
                .then((response) => {
                    this.items = response.data.data;
                    this.totalPages = response.data.total;
                    this.loading = false;
                    this.filtersInitializationDone = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        composeAndSaveFiltersToStore() {
            let filters = {
                per_page: this.perPage,
                page: this.crtPage,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                shopId: this.notNullOrEmptyObject(this.shopsModel) ? this.shopsModel.id : this.defaultShopId,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        getGameName(productUrl) {
            var game = 'OTHER';
            if (productUrl.indexOf(settings.gameType.magnumsix) >= 0) {
                game = 'BALLSIX1';
            } else {
                if (productUrl.indexOf(settings.gameType.kenogame) >= 0) {
                    game = 'KENO1';
                } else {
                    if (productUrl.indexOf(settings.gameType.keno) >= 0) {
                        game = 'KENO';
                    } else {
                        if (productUrl.indexOf(settings.gameType.ballsix) >= 0) {
                            game = 'BALLSIX';
                        } else {
                            if (productUrl.indexOf(settings.gameType.devicename) >= 0) {
                                game = 'DEVICENAME';
                            } else {
                                if (productUrl.indexOf(settings.gameType.lotto) >= 0) {
                                    game = 'LOTTO';
                                } else {
                                    if (productUrl.indexOf(settings.gameType.live2) >= 0) {
                                        game = 'LIVE MONITOR 2';
                                    } else {
                                        if (productUrl.indexOf(settings.gameType.live) >= 0) {
                                            game = 'LIVE';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return game;
        },
        resetDevice(deviceId) {
            this.BoApi.resetDevice(deviceId)
                .then(() => {
                    this.readItems();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        refresh() {
            if (this.activeTab == 0) {
                this.readItems();
            } else {
                this.getPendingDevices();
            }
        },
        deleteDevice(deviceId) {
            this.BoApi.deleteDevice(deviceId)
                .then(() => {
                    this.readItems();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        createShopsDictionary() {
            for (let i = 0; i < this.shops.length; i++) {
                this.shopsDictionary[this.shops[i]._id] = this.shops[i].code + ' - ' + this.shops[i].name;
            }
        },
        getPendingDevices() {
            this.BoApi.getPendingDevices()
                .then((response) => {
                    this.pendingItems = response.data;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
    },
};
</script>
